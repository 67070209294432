import { RedesSociaisRodapeInterface } from "../../interfaces/interface";

const constRedesSociaisRodape: Array<RedesSociaisRodapeInterface> = [
        {
            title: 'Instagram',
            url: 'https://www.instagram.com/flexprosistemas',
            icon: 'instagram'
        },
        {
            title: 'Facebook',
            url: 'https://www.facebook.com/flexprosistemas',
            icon: 'facebook'
        },
        {
            title: 'YouTube',
            url: 'https://www.youtube.com/channel/UCC6eaa-9eQthRIPNt0SVQUw',
            icon: 'youtube'
        },
        {
            title: 'LinkedIn',
            url: 'https://br.linkedin.com/company/flexprosistemas',
            icon: 'linkedin'
        }
    ];

export default constRedesSociaisRodape;