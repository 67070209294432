import './Card.css'
import { CardPropsInterface } from "../../_config/interfaces/interface"

function Card(props: CardPropsInterface) {
    const cardStyle: React.CSSProperties = {
        backgroundColor: props.backgroundColor,
        boxShadow: props.backgroundColor ? '' : 'var(--floatShadow)',
        width: props.width || '100%',
        minWidth: props.minWidth || '',
        height: props.height || '',
        maxHeight: props.maxHeight || '',
        color: props.color,
        maxWidth: props.maxWidth || ''
    }

    return (
        <div className={'cardContainer ' + props.class} style={cardStyle}>
            {props.text}
        </div>
    )
}

export default Card