import { Link } from 'react-router-dom';
import './MenuItems.css'
import Dropdown from '../../../../components/dropdown/Dropdown';
import { MenuItemsInterface } from '../../../../_config/interfaces/interface';

function MenuItems(props: { items: MenuItemsInterface }) {
    
    function handleMenuClick() {
        window.scrollTo(0, 0)
    }
    
    return (
        <div className="singleMenuItem">
            <Link to={props.items.url ? props.items.url : ''} target={props.items.target}><button className="menuItemButton" onClick={handleMenuClick}>{props.items.title}</button></Link>
            {props.items.submenus && <Dropdown submenus={props.items.submenus} />}
        </div>
    );
}

export default MenuItems;