import { Link } from 'react-router-dom';
import constLinksRodape from '../../../_config/const/constRodape/constLinksRodape';

import './Sitemap.css';

function Sitemap(props: {isLanding?: boolean}) {

    const filteredLinks = constLinksRodape.map(link => {
        if (props.isLanding && !link.target) {
            return { ...link, target: '_blank' };
        }
        return link;
    }).filter(link => {
        if (props.isLanding) {
            return link.textUrl == 'Nossos Termos' || link.textUrl == 'Política de Privacidade';
        } else {
            return true;
        }
    });

    const links = filteredLinks.map((link, index) => (
        <Link to={link.url} target={link.target || "_self"} key={index}>{link.textUrl}</Link>
    ));

    return (
        <div className='sitemap'>
            <h2>Links Úteis</h2>

            <div className='links'>
                {links}
            </div>

        </div>
    )
}

export default Sitemap;