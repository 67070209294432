import { Link } from "react-router-dom";

import './Dropdown.css';
import { DropdownPropsInterface } from "../../_config/interfaces/interface";

function Dropdown(props: DropdownPropsInterface) {
    const displaySubmenus = props.submenus?.map((submenu, index) => {
        return (
            <li key={index} className="singleSubmenus">
                <Link to={submenu.url ? submenu.url : '/'} target={submenu.target} className="linkSubmenu">{submenu.title}</Link>
            </li>
        )
    })

    return (
        <div className="dropdown">
            <ul className="submenus">
                {displaySubmenus}
            </ul>
        </div>
    );
}

export default Dropdown;