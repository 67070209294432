import { Link } from 'react-router-dom';

import './Menu.css';
import Navbar from './navbar/Navbar';

function Menu(props?: {styled?:string}) {
    
    function handleMenuClick() {
        window.scrollTo(0, 0)
    }

    return (
        <div className={props?.styled === 'dark' ?"menuDark" : "menu"}>
            <div className="flexLogo" onClick={handleMenuClick}>
                <Link to="/"><img src="./_assets/images/FlexLogo.png" alt="Flexpro logo" /></Link>
            </div>
            <Navbar />
        </div>
    )
}

export default Menu;