const opcoesCompra = [
    {
        icon: 'laptop',
        title: 'CPF A1',
        price: 'R$ 134,90',
    },
    {
        icon: 'reward',
        title: 'CNPJ A1',
        price: 'R$ 189,90',
    }
];


export default opcoesCompra;