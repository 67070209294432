import Contato from './contato/Contato';
import Sitemap from './sitemap/Sitemap';
import Social from './social/Social';

import './Rodape.css';

function Rodape(props: {isLanding?: boolean}) {
    return (
        <div className={'rodapeContainer'} id="rodape">
            <div className={'rodape'}>
                <Social />
                <Sitemap isLanding={props.isLanding} />
                <Contato />
            </div>
        </div>
    )
}

export default Rodape;