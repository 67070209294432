import { useEffect, useState } from "react";
import useRouterHash from "../../_config/hooks/useRouterHash";
import BannerCertificadoDigital from "../../partials/certificadoDigital/bannerCertificadoDigital/BannerCertificadoDigital";
import BeneficiosCertificadoDigital from "../../partials/certificadoDigital/beneficiosCertificadoDigital/BeneficiosCertificadoDigital";
import OpcoesCertificadoDigital from "../../partials/certificadoDigital/opcoesCertificadoDigital/OpcoesCertificadoDigital";
import PassoAPassoCompra from "../../partials/certificadoDigital/passoApassoCompra/PassoAPassoCompra";
import PerguntasFrequentes from "../../partials/certificadoDigital/perguntasFrequentes/PerguntasFrequentes";
import Menu from "../../partials/menus/menu/Menu";
import MenuMobile from "../../partials/menus/menuMobile/MenuMobile";
import Rodape from "../../partials/rodape/Rodape";
import RodapeMobile from "../../partials/rodape/rodapeMobile/RodapeMobile";
import styles from './CertificadoDigital.module.css';

export default function CertificadoDigital(props: { isMobile: boolean }) {
    const [isChatOpen, setIsChatOpen] = useState(false);

    window.scrollTo(0, 0);

    useEffect(() => {
        checkIframe();

        const observer = new MutationObserver(checkIframe);
        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    function checkIframe() {
        const iframe = document.querySelector('iframe[src="https://websdk.hyperflow.global"]') as HTMLIFrameElement;
        if (iframe) {
            const iframeWidth = iframe.style.width || getComputedStyle(iframe).width;
            setIsChatOpen(parseInt(iframeWidth, 10) > 0);
        }
    }

    return (
        <div className={styles.container}>
            {!props.isMobile ? <Menu /> : <MenuMobile />}
            <BannerCertificadoDigital isMobile={props.isMobile} />
            <OpcoesCertificadoDigital />
            <BeneficiosCertificadoDigital isMobile={props.isMobile} />
            <PassoAPassoCompra />
            <PerguntasFrequentes />
            {!isChatOpen && <span className={styles.labelChat}>Converse com a gente</span>}
            {!props.isMobile ? <Rodape /> : <RodapeMobile />}

        </div>
    );
}
