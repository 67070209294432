import { lazy } from 'react';
import styles from './RodapeMobile.module.css';

const LinksUteisRodape = lazy(() => import('../links/linksUteisRodape'));
const Contato = lazy(() => import('../contato/Contato'));
const SocialMedia = lazy(() => import('../midiasSociais/SocialMedia'));
const Social = lazy(() => import('../social/Social'));

export default function RodapeMobile(props: {isLanding?: boolean}) {
    return (
        <div className={styles.rodapeMobile} id="rodape">
            {!props.isLanding && <LinksUteisRodape />}
            {!props.isLanding && <SocialMedia />}
            <Contato isMobile={true} />
            <Social isMobile={true} isLanding={props.isLanding} />
        </div>
    )
}