import Texto from '../../../components/fontSizes/texto/Texto';
import Icon from '../../../components/icon/Icon';
import './Contato.css';

function Contato(props: { isMobile?: boolean }) {

    const enderecoFlexpro: string = "https://www.google.com.br/maps/place/R.+Joaquim+Nabuco,+1493+-+S%C3%A3o+Jorge,+S%C3%A3o+Miguel+do+Oeste+-+SC,+89900-000/@-26.7464185,-53.5117745,17z/data=!3m1!4b1!4m6!3m5!1s0x94fa5c7853414e91:0x2b1caac28c2cfc76!8m2!3d-26.7464185!4d-53.5117745!16s%2Fg%2F11q4g3v0yv?hl=pt-BR";

    function openUrl(url: string) {
        window.open(url, '_blank');
    }

    return (
        <div className={'contatos'}>

            <b><Texto texto={'Contato'} /></b>

            <div className='contato'>
                <Icon
                    icon="telefone"
                    class='img-align'
                    colorCircle={!props.isMobile ? 'var(--white)' : 'var(--darkGray)'}
                    color={!props.isMobile ? 'var(--darkGray)' : 'var(--white)'} />
                <div className='telefone'>
                    <Texto texto={'SC: +55 (49) 3622-8784'} />
                    <Texto texto={'SP: +55 (11) 4280-5787'} />
                    <Texto texto={'RS: +55 (51) 3500-0290'} />
                </div>
            </div>
            <div className='contato'>
                <Icon
                    icon="email"
                    class='img-align'
                    colorCircle={!props.isMobile ? 'var(--white)' : 'var(--darkGray)'}
                    color={!props.isMobile ? 'var(--darkGray)' : 'var(--white)'} />
                <Texto className='email' texto={'comercial@flexpro.com.br'} />
            </div>
            <div className='contato'>
                <Icon
                    icon="localizacao"
                    class='img-align'
                    colorCircle={!props.isMobile ? 'var(--white)' : 'var(--darkGray)'}
                    color={!props.isMobile ? 'var(--darkGray)' : 'var(--white)'} />
                <div className='endereco' onClick={() => openUrl(enderecoFlexpro)} >
                    <Texto texto={'Matriz:'} />
                    <Texto texto={'Rua Joaquim Nabuco, 1493 | Bairro São Jorge'} />
                    <Texto texto={'São Miguel do Oeste - SC | CEP: 89900-000'} />
                </div>
            </div>
        </div>
    )
}

export default Contato;