import Label from '../../../components/fontSizes/label/Label';
import Texto from '../../../components/fontSizes/texto/Texto';
import Button from '../../../components/buttons/Button';
import styles from './BannerCertificadoDigital.module.css';

function BannerCertificadoDigital(props: { isMobile: boolean }) {

    function openCompreCertificado() {
        window.open("https://service.certcontrol.com.br/venda-rapida/vd0tp7877tk7d4e93741c", '_blank');
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <div className={`title ${styles.title}`}>
                        <h1>Adquira agora seu Certificado Digital</h1>
                        <Texto texto={'Fácil, rápido e totalmente on-line.'} />
                    </div>
                    <div className={styles.description}>
                        <Label texto={'Simplifique e agilize a compra do seu Certificado Digital CNPJ A1 e CPF A1.'} />
                    </div>
                    <Button
                        class={styles.certificadoButton}
                        text={'Compre seu Certificado Digital'}
                        onClick={openCompreCertificado}
                    />
                </div>
                <div className={styles.img}><img src='/_assets/images/mulherCertificado.png' /></div>
                <div className={styles.shadow}></div>
            </div>

        </div>
    )
}

export default BannerCertificadoDigital;
