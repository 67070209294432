
import { TextoComponentInterface } from "../../../_config/interfaces/interface";
import TextoComponent from "../textoComponent/TextoComponent";
import "./Label.css";

export default function Label(props: TextoComponentInterface) {
    return (
        <TextoComponent {...props} styleClass={'label'} />
    )
}
