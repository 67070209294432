import constEtapas from "../../../_config/const/constCertificadoDigital/constEtapas";
import Button from "../../../components/buttons/Button";
import Icon from "../../../components/icon/Icon";
import styles from './PassoAPassoCompra.module.css';

export default function PassoAPassoCompra() {

    function openPassoAPasso() {
        window.open("/files/passoapasso.pdf", '_blank');
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}><p>Passo a passo para a compra do Certificado Digital</p></div>
            <div className={styles.cards}>
                {constEtapas.map((item, index) => (
                    <div className={styles.card} style={{ background: item.color }} key={index}>
                        <div className={styles.containerNumber}>{item.number}</div>
                        <div className={styles.description}>{item.description}</div>
                        {item.icon && <Icon icon={'checkFlag'} class={styles.icon} />}
                    </div>
                ))}
            </div>
            <Button
                class={styles.openPassoAPasso}
                text={'Acessar passo a passo completo'}
                onClick={openPassoAPasso}
            />
        </div>
    )
}