import { IconConstInterface } from '../../interfaces/interface';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LinkIcon from '@mui/icons-material/Link';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SettingsIcon from '@mui/icons-material/Settings';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ConstructionIcon from '@mui/icons-material/Construction';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import NoteIcon from '@mui/icons-material/Note';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GavelIcon from '@mui/icons-material/Gavel';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BusinessIcon from '@mui/icons-material/Business';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import StoreIcon from '@mui/icons-material/Store';
import WorkIcon from '@mui/icons-material/Work';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MoneyIcon from '@mui/icons-material/Money';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SavingsIcon from '@mui/icons-material/Savings';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import FeedIcon from '@mui/icons-material/Feed';
import ShareIcon from '@mui/icons-material/Share';
import ApartmentIcon from '@mui/icons-material/Apartment';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowSmallLeft from '@mui/icons-material/ArrowBackIosNew';
import ArrowSmallRight from '@mui/icons-material/ArrowForwardIos';

/**
 * ícones: https://mui.com/material-ui/material-icons
 */

const constIcons: Array<IconConstInterface> = [
        {
                name: 'arrowRight',
                component: <ArrowForwardOutlinedIcon />,
        },
        {
                name: 'arrowLeft',
                component: <ArrowBackIcon />,
        },
        {
                name: 'personGroup',
                component: <PeopleAltOutlinedIcon />,
        },
        {
                name: 'link',
                component: <LinkIcon />,
        },
        {
                name: 'smartphone',
                component: <SmartphoneIcon />,
        },
        {
                name: 'arrowRight',
                component: <ArrowForwardIcon />,
        },
        {
                name: 'arrowDown',
                component: <ArrowDownwardIcon />,
        },
        {
                name: 'arrowUp',
                component: <ArrowUpwardIcon />,
        },
        {
                name: 'arrowForwardIosIcon',
                component: <ArrowForwardIosIcon />,
        },        
        {
                name: 'instagram',
                component: <InstagramIcon />,
        },
        {
                name: 'facebook',
                component: <FacebookOutlinedIcon />,
        },
        {
                name: 'youtube',
                component: <YouTubeIcon />,
        },
        {
                name: 'linkedin',
                component: <LinkedInIcon />,
        },
        {
                name: 'whatsapp',
                component: <WhatsAppIcon />,
        },
        {
                name: 'email',
                component: <MailOutlineIcon />,
        },
        {
                name: 'telefone',
                component: <CallIcon />,
        },
        {
                name: 'localizacao',
                component: <LocationOnOutlinedIcon />,
        },
        {
                name: 'setting',
                component: <SettingsIcon />,
        },
        {
                name: 'like',
                component: <ThumbUpAltOutlinedIcon />,
        },
        {
                name: 'phoneTalk',
                component: <PhoneInTalkIcon />,
        },
        {
                name: 'home',
                component: <HomeOutlinedIcon />,
        },
        {
                name: 'statistic',
                component: <TrendingUpOutlinedIcon />,
        },
        {
                name: 'chart',
                component: <BarChartOutlinedIcon />,
        },
        {
                name: 'localOffer',
                component: <LocalOfferOutlinedIcon />,
        },
        {
                name: 'filter',
                component: <FilterAltOutlinedIcon />,
        },
        {
                name: 'loop',
                component: <LoopOutlinedIcon />,
        },
        {
                name: 'calendar',
                component: <DateRangeOutlinedIcon />,
        },
        {
                name: 'text',
                component: <TextSnippetIcon />,
        },
        {
                name: 'play',
                component: <PlayArrowOutlinedIcon />,
        },
        {
                name: 'pause',
                component: <PauseOutlinedIcon />,
        },
        {
                name: 'menu',
                component: <MenuIcon />,
        },
        {
                name: 'newspaper',
                component: <NewspaperIcon />,
        },
        {
                name: 'payments',
                component: <PaymentsIcon />,
        },
        {
                name: 'receipt',
                component: <ReceiptLongIcon />,
        },
        {
                name: 'bank',
                component: <AccountBalanceIcon />,
        },
        {
                name: 'construction',
                component: <ConstructionIcon />,
        },
        {
                name: 'paid',
                component: <PaidRoundedIcon />,
        },
        {
                name: 'summarize',
                component: <SummarizeIcon />,
        },
        {
                name: 'fireExtinguisher',
                component: <FireExtinguisherIcon />,
        },
        {
                name: 'note',
                component: <NoteIcon />,
        },
        {
                name: 'eletronicSignature',
                component: <SecurityUpdateGoodIcon />,
        },
        {
                name: 'notification',
                component: <NotificationsIcon />,
        },
        {
                name: 'creditCard',
                component: <CreditCardIcon />,
        },
        {
                name: 'house',
                component: <MapsHomeWorkIcon />,
        },
        {
                name: 'admin',
                component: <AdminPanelSettingsIcon />,
        },
        {
                name: 'clip',
                component: <AttachFileIcon />,
        },
        {
                name: 'hammer',
                component: <GavelIcon />,
        },
        {
                name: 'dashboard',
                component: <DashboardIcon />,
        },
        {
                name: 'addRight',
                component: <ControlPointDuplicateIcon />,
        },
        {
                name: 'priceChange',
                component: <PriceChangeIcon />,
        },
        {
                name: 'otherHouse',
                component: <OtherHousesIcon />,
        },
        {
                name: 'business',
                component: <BusinessIcon />,
        },
        {
                name: 'addBusiness',
                component: <AddBusinessIcon />,
        },
        {
                name: 'roomPreferences',
                component: <RoomPreferencesIcon />,
        },
        {
                name: 'corporate',
                component: <CorporateFareIcon />,
        },
        {
                name: 'appShortcut',
                component: <AppShortcutIcon />,
        },
        {
                name: 'StoreIcon',
                component: <StoreIcon />,
        },
        {
                name: 'work',
                component: <WorkIcon />,
        },
        {
                name: 'personPin',
                component: <PersonPinIcon />,
        },
        {
                name: 'wallet',
                component: <AccountBalanceWalletIcon />,
        },
        {
                name: 'sale',
                component: <PointOfSaleIcon />,
        },
        {
                name: 'priceCheck',
                component: <PriceCheckIcon />,
        },
        {
                name: 'moneyNote',
                component: <RequestQuoteIcon />,
        },
        {
                name: 'graphicUp',
                component: <ShowChartIcon />,
        },
        {
                name: 'moneyIcon',
                component: <MoneyIcon />,
        },
        {
                name: 'noMoney',
                component: <MoneyOffIcon />,
        },
        {
                name: 'currencyExchange',
                component: <CurrencyExchangeIcon />,
        },
        {
                name: 'doubleGraphic',
                component: <SsidChartIcon />,
        },
        {
                name: 'multilineGraphic',
                component: <MultilineChartIcon />,
        },
        {
                name: 'paperCheck',
                component: <FactCheckIcon />,
        },
        {
                name: 'verified',
                component: <VerifiedIcon />,
        },
        {
                name: 'person',
                component: <PersonOutlineOutlinedIcon />,
        },
        {
                name: 'personSetting',
                component: <ManageAccountsIcon />,
        },        
        {
                name: 'savings',
                component: <SavingsIcon />,
        },
        {
                name: 'phone',
                component: <PhoneIcon />,
        },
        {
                name: 'personSearch',
                component: <PersonSearchIcon />,
        },
        {
                name: 'savedSearch',
                component: <SavedSearchIcon />,
        },
        {
                name: 'feed',
                component: <FeedIcon />,
        },
        {
                name: 'share',
                component: <ShareIcon />,
        },
        {
                name: 'apartment',
                component: <ApartmentIcon />,
        },
        {
                name: 'homeSvg',
                src: './_assets/icons/home.svg',
        },
        {
                name: 'sellSvg',
                src: './_assets/icons/sell.svg',
        },
        {
                name: 'buildingSvg',
                src: './_assets/icons/building.svg',
        },
        {
                name: 'financialSvg',
                src: './_assets/icons/financial.svg',
        },
        {
                name: 'renegotiationSvg',
                src: './_assets/icons/renegotiation.svg',
        },
        {
                name: 'graphicSvg',
                src: './_assets/icons/graphic.svg',
        },
        {
                name: 'handshakeSvg',
                src: './_assets/icons/handshake.svg',
        },
        {
                name: 'touchSvg',
                src: './_assets/icons/touch.svg',
        },
        {
                name: 'doubleArrowLeft',
                component: <KeyboardDoubleArrowLeftIcon />,
        },
        {
                name: 'doubleArrowRight',
                component: <KeyboardDoubleArrowRightIcon />,
        },
        {
                name: 'arrowSmallLeft',
                component: <ArrowSmallLeft />,
        },
        {
                name: 'arrowSmallRight',
                component: <ArrowSmallRight />,
        },
        {
                name: 'loading',
                src: './_assets/icons/loading.svg',
        },
        {
                name: 'airplay',
                src: './_assets/icons/airplay.svg',
        },
        {
                name: 'layout',
                src: './_assets/icons/layout.svg',
        },
        {
                name: 'clock',
                src: './_assets/icons/clock.svg',
        },
        {
                name: 'feather',
                src: './_assets/icons/feather.svg',
        },
        {
                name: 'plusCircle',
                src: './_assets/icons/plusCircle.svg',
        },
        {
                name: 'minusCircle',
                src: './_assets/icons/minusCircle.svg',
        },
        {
                name: 'chevronLeft',
                src: './_assets/icons/chevronLeft.svg',
        },
        {
                name: 'chevronRight',
                src: './_assets/icons/chevronRight.svg',
        },
        {
                name: 'check',
                src: './_assets/icons/check.svg',
        },
        {
                name: 'close',
                src: './_assets/icons/close.svg',
        },
        {
                name: 'chevronDown',
                src: './_assets/icons/chevronDown.svg',
        },
        {
                name: 'chevronUp',
                src: './_assets/icons/chevronUp.svg',
        },
        {
                name: 'alert',
                src: './_assets/icons/alert.svg',
        },
        {
                name: 'thumbsUp',
                src: './_assets/icons/thumbsUp.svg',
        },
        {
                name: 'laptop',
                src: './_assets/icons/laptop.svg',
        },
        {
                name: 'reward',
                src: './_assets/icons/reward.svg',
        },
        {
                name: 'monitor',
                src: './_assets/icons/monitor.svg',
        },
        {
                name: 'speed',
                src: './_assets/icons/speed.svg',
        },
        {
                name: 'sportsScore',
                src: './_assets/icons/sportsScore.svg',
        },
        {
                name: 'socialLeaderboard',
                src: './_assets/icons/socialLeaderboard.svg',
        },
        {
                name: 'celebration',
                src: './_assets/icons/celebration.svg',
        },
        {
                name: 'piggyBank',
                src: './_assets/icons/piggyBank.svg',
        },
        {
                name: 'verifiedUser',
                src: './_assets/icons/verifiedUser.svg',
        },
        {
                name: 'checkFlag',
                src: './_assets/icons/checkFlag.svg',
        },
];

export default constIcons;