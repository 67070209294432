import { EventEmitterInterface } from "./_config/interfaces/interface";

declare global {
    var EventEmitter: EventEmitterInterface;
    var gtag: any
}

globalThis.EventEmitter = {
    events: {},
    subscribe: (name, func): string => {
        const id = window.crypto.randomUUID().toString();
        if (!globalThis.EventEmitter.events[name]) {
            globalThis.EventEmitter.events[name] = {};
        }
        globalThis.EventEmitter.events[name][id] = func;
        return id;
    },
    emit: (name, data = null) => {
        if (globalThis.EventEmitter.events[name]) {
            let retornos: Array<any> = [];
            Object.keys(globalThis.EventEmitter.events[name]).forEach((key) => {
                let valor;
                if (typeof data === "object") {
                    valor = globalThis.EventEmitter.events[name][key]({...data})
                } else {
                    valor = globalThis.EventEmitter.events[name][key](data)
                }
                retornos.push(valor);
            });
            if (retornos.length === 1) {
                return retornos[0];
            }
            return retornos;
        }
        return null;
    },
    remove: (id) => {
        Object.keys(globalThis.EventEmitter.events).forEach(name => {
            if (globalThis.EventEmitter.events[name][id]) {
                delete globalThis.EventEmitter.events[name][id];
            }
            if (!Object.keys(globalThis.EventEmitter.events[name]).length) {
                delete globalThis.EventEmitter.events[name];
            }
        })
    }
}