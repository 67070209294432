import { HelmetProvider } from 'react-helmet-async';
import RouteManager from './_config/routes/RouteManager';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <RouteManager />
    </HelmetProvider>
  );
}

export default App;
