const perguntasFrequentes = [
    {
        pergunta: 'Quem é o representante legal da empresa?',
        resposta: 'Processo totalmente digital, feito por videoconferência, para sua comodidade.'
    },
    {
        pergunta: 'Quem pode realizar a videoconferência?',
        resposta: 'A vídeo conferência deve ser realizada pelo Representante legal da empresa. O mesmo deve estar em posse da CNH original em mãos, ou com o aplicativo da carteira digital aberto.'
    },
    {
        pergunta: 'Não consegui acessar/baixar o emissor do certificado. O que pode ser?',
        resposta: 'Pode ser que seu computador não tenha o Java Instalado/atualizado, e nesse caso é necessário fazer download e instalação/atualização do Java. Após realizar o download e instalação do Java, fazer novamente o download do emissor do certificado.'
    },
    {
        pergunta: 'Esqueci a senha de reagendamento, o que fazer?',
        resposta: 'A senha criada para solicitar o reagendamento deve ser anotada, pois caso esqueça, será necessário refazer toda a etapa de solicitação do Certificado novamente.'
    },
    {
        pergunta: 'Esqueci a senha de download do Certificado, e agora?',
        resposta: 'Caso esqueça a senha de download, será necessário adquirir um novo Certificado. A senha de download é pessoal e intransferível. A senha deverá ter no mínimo 4 e no máximo 16 caracteres, seja números ou letras.'
    },
    {
        pergunta: 'Perdi a data da videoconferência, e agora?',
        resposta: 'Caso precise realizar um reagendamento da videoconferência, acesse o link abaixo e informe o protocolo, que foi enviado no e-mail do solicitante, e senha criada no agendamento inicial.',
        linkTexto: 'Reagendamento aqui',
        linkUrl: 'https://artecnosign.acsoluti.com.br/site/agendamento-upload-login'
    }
];


export default perguntasFrequentes;