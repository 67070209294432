import { Suspense, lazy, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SubmissionMessage from '../../pages/submissionMessage/SubmissionMessage';
import CertificadoDigital from '../../pages/certificadoDigital/CertificadoDigital';

const Home = lazy(() => import("../../pages/home/Home"));
const PoliticaPrivacidade = lazy(() => import("../../pages/politicaPrivacidade/PoliticaPrivacidade"));
const Simob = lazy(() => import("../../pages/simob/Simob"));
const NotFound = lazy(() => import("../../pages/notFound/NotFound"));
const Moov = lazy(() => import("../../pages/moov/Moov"));
const Aplicativo = lazy(() => import("../../pages/aplicativo/Aplicativo"));
const Contato = lazy(() => import("../../pages/contato/Contato"));
const AboutCompany = lazy(() => import("../../pages/aboutCompany/AboutCompany"));
const EBook = lazy(() => import('../../pages/eBook/EBook'));
const ParceriaPage = lazy(() => import('../../pages/parceriaPage/ParceriaPage'));
const LandingSimob = lazy(() => import('../../pages/landingSimob/LandingSimob'));
const LandingMoov = lazy(() => import('../../pages/landingMoov/LandingMoov'));

function RouteManager() {

    const [isMobile, setIsMobile] = useState((window.innerWidth > 1000 ? false : true));

    window.addEventListener('resize', function () {
        setIsMobile((window.innerWidth > 1000 ? false : true));
    })

    return (
        <Router>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path={"/"} element={<Home isMobile={isMobile} />} />
                    <Route path={"/SolucaoSimob"} element={<LandingSimob isMobile={isMobile} />} />
                    <Route path={"/SolucaoMoov"} element={<LandingMoov isMobile={isMobile} />} />
                    <Route path={"/confirmacao"} element={<SubmissionMessage />} />
                    <Route path={"/simob"} element={<Simob isMobile={isMobile} />} />
                    <Route path={"/moov"} element={<Moov isMobile={isMobile} />} />
                    <Route path={"/app"} element={<Aplicativo isMobile={isMobile} />} />
                    <Route path={"/contato"} element={<Contato isMobile={isMobile} />} />
                    <Route path={"/sobre-nos"} element={<AboutCompany isMobile={isMobile} />} />
                    <Route path={"/politica-privacidade"} element={<PoliticaPrivacidade isMobile={isMobile} />} />
                    <Route path={"/conteudos"} element={<EBook isMobile={isMobile} />} />
                    <Route path={"/certificado-digital"} element={<CertificadoDigital isMobile={isMobile} />} />
                    <Route path={"/parceria/:siteReferencia"} element={<ParceriaPage />} />
                    <Route path={"*"} element={<NotFound />} />
                </Routes>
            </Suspense>
        </Router>
    )
}

export default RouteManager;