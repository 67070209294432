import { Link } from "react-router-dom";
import Texto from "../../components/fontSizes/texto/Texto";
import Icon from "../../components/icon/Icon";
import styles from './submissionMessage.module.css';

export default function SubmissionMessage() {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Icon icon={'thumbsUp'} />
                <h2 className={styles.title}>Obrigado!</h2>
                <Texto className={styles.description} texto={'Nossa equipe entrará em contato em breve para discutir suas necessidades e fornecer informações adicionais sobre nossos produtos.'} />
                <Texto texto={'Enquanto isso, sinta-se à vontade para explorar mais sobre nossos produtos e serviços em nosso site. Estamos ansiosos para ajudá-lo a encontrar a solução perfeita para suas necessidades.'} />
                <Link to="/" className={styles.link}><Texto className={styles.description} texto={'www.flexpro.com.br'} /></Link>
            </div>
        </div>
    )
}
