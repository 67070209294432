import './Button.css';

import { ButtonPropsInterface } from '../../_config/interfaces/interface';
import Icon from '../icon/Icon';

function Button(props: ButtonPropsInterface) {

    return (
        <button
            className={'generalStyle ' + props.class + " " + (props.styled ? props.styled : '') + " " + props.animation}
            style={{width: props.width, pointerEvents: (props.styled === 'disabled' ? 'none' :'auto')}}
            onClick={props.onClick}
            aria-label={props?.label}
            onMouseOver={props.onMouseOver}
        >
            {!props.loading ?
                <>
                    {props.icon &&
                        <Icon icon={props.icon} color={props.styled} />
                    }
                    {props.text}
                </>
                :
                <div className={(props.loading ? 'loadingButton' : '')}></div>
            }
        </button>
    )
}

export default Button;