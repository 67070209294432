
import constBeneficiosCertificadoDigital from "../../../_config/const/constCertificadoDigital/constBeneficiosCertificadoDigital";
import Button from "../../../components/buttons/Button";
import HorizontalScroll from "../../../components/horizontalScroll/HorizontalScroll";
import Icon from "../../../components/icon/Icon";
import styles from "./BeneficiosCertificadoDigital.module.css";

export default function BeneficiosCertificadoDigital(props: { isMobile: boolean }) {

    const beneficios = constBeneficiosCertificadoDigital.map((beneficio, index) => (
        <div className={styles.benefit} key={index}>
            <div className={styles.benefitCard}>
                {beneficio.icon && <Icon icon={beneficio.icon} />}
                <p dangerouslySetInnerHTML={{ __html: beneficio.text }}></p>
            </div>
        </div>
    ));

    function openAdquiraCertificado() {
        window.open("https://service.certcontrol.com.br/venda-rapida/vd0tp7877tk7d4e93741c", '_blank');
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <p>Benefícios Exclusivos do Certificado Digital Online pela Flexpro Sistemas</p>
                </div>

                <div className={styles.benefits}>
                    {!props.isMobile ? beneficios : <HorizontalScroll children={beneficios} />}
                </div>

                <Button
                    class={styles.adquiraCertificadoButton}
                    text={'Compre seu Certificado Digital'}
                    onClick={openAdquiraCertificado}
                />
            </div>
        </div>
    )
}