import { MoovInterface } from "../../interfaces/interface";

const constBeneficiosCertificadoDigital: Array<MoovInterface> = [
    {
        icon: 'monitor',
        text: "<b>Emissão Online</b> <br /> Processo totalmente digital, feito por videoconferência, para sua comodidade."
    },
    {
        icon: 'speed',
        text: "<b>Agilidade e Flexibilidade</b> <br />Obtenha seu certificado de forma rápida e escolha os horários mais convenientes."
    },
    {
        icon: 'sportsScore',
        text: "<b>Zero Burocracia</b> <br /> Livre-se da papelada e economize tempo."
    },
    {
        icon: 'socialLeaderboard',
        text: "<b>Compra Simplificada</b> <br /> Processo de compra e agendamento descomplicado."
    },
    {
        icon: 'celebration',
        text: "<b>Comodidade em Qualquer Lugar</b> <br /> Valide seu certificado de onde estiver, seja no trabalho ou em casa."
    },
    {
        icon: 'piggyBank',
        text: "<b>Economia e Custo-benefício</b> <br /> Acesso a um certificado digital de qualidade com vantagens financeiras."
    },
    {
        icon: 'verifiedUser',
        text: "<b>Segurança de Dados Garantida</b> <br /> Mantemos seus dados protegidos durante todo o processo."
    }
]

export default constBeneficiosCertificadoDigital;