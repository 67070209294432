import { Link, useLocation } from 'react-router-dom';
import constMenuItems from '../../../../_config/const/constMenu/constMenuItems';
import './MenuItemsMobile.css';

function MenuItemsMobile(props: {updateStatusMenu: () => void}) {
    const location = useLocation();

    function handleMenuItemClick() {
        props.updateStatusMenu();
    }

    return (
        <div className="menuItemsMobile">
            {constMenuItems.map((menuItem, index) => {
                const isActive = location.pathname === menuItem.url;
                return (
                    <div className={`menuItem ${isActive ? 'menuActive' : ''}`} key={index} onClick={handleMenuItemClick}>
                        <Link to={menuItem.url}>{menuItem.title}</Link>
                    </div>
                )
            })}
        </div>
    );
}

export default MenuItemsMobile;