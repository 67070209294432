import constIcons from "../../_config/const/constIcons/constIcons";
import { IconInterface } from "../../_config/interfaces/interface";
import "./Icon.css";


function Icon(props: IconInterface) {

    const icon = constIcons.find(ci => ci.name === props.icon);

    return (
        <span onClick={props.onClick} title={props?.title} id={props?.id} className={"icon " + props.class + (props?.colorCircle ? ' circle':'')} style={{color: props.color, backgroundColor: props.colorCircle, width: props.width, height: props.height, border: props.border}}>
            {icon?.component &&
                icon.component
            }
            {icon?.src &&
                <img src={icon.src} alt={icon.name} className={props.classImage} />
            }
        </span>
    );
}

export default Icon;