import './Navbar.css'
import constMenuItems from '../../../../_config/const/constMenu/constMenuItems';
import MenuItems from '../menuItems/MenuItems';

function Navbar() {
    const displayMenuItems = constMenuItems.map((menu, index) => {
        return <MenuItems items={menu} key={index} />
    })

    return (
        <div className="menuItems">
            {displayMenuItems}
        </div>
    );
}

export default Navbar;