const constEtapas = [
    {
        number: '01',
        description: 'Compra',
        color: '#E2F8FF'
    },
    {
        number: '02',
        description: 'Pagamento',
        color: '#E5F0FF'
    },
    {
        number: '03',
        description: 'Documentos',
        color: '#ECFFF2'
    },
    {
        number: '04',
        description: 'Agendar',
        icon: 'checkFlag',
        color: '#EFECFD'
    }
];

export default constEtapas;