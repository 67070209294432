import opcoesCompra from "../../../_config/const/constCertificadoDigital/constOpcoesCompra";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/card/Card";
import Icon from "../../../components/icon/Icon";
import styles from './OpcoesCertificadoDigital.module.css';

export default function OpcoesCertificadoDigital() {

    function openCompreCertificado() {
        window.open("https://service.certcontrol.com.br/venda-rapida/vd0tp7877tk7d4e93741c", '_blank');
    }

    return (
        <div className={styles.container}>
            <div className='title'>
                <h2>Escolha a sua opção de Certificado Digital</h2>
            </div>
            <div className={styles.containerCard}>
                {opcoesCompra.map((item, index) => (
                    <Card
                        class={styles.card}
                        key={index}
                        text={
                            <div className={styles.cardContent}>
                                <Icon icon={item.icon} />
                                <div>
                                    <h3 className={styles.cardTitle}>{item.title}</h3>
                                    <h3 className={styles.price}>{item.price}</h3>
                                </div>
                                <Button
                                    class={styles.certificadoButton}
                                    text={'Comprar agora'}
                                    onClick={openCompreCertificado}
                                />
                            </div>
                        }
                    />
                ))}
            </div>
        </div>
    )
}