import perguntasFrequentes from "../../../_config/const/constCertificadoDigital/constPerguntasFrequentes";
import styles from './PerguntasFrequentes.module.css';

export default function PerguntasFrequentes() {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div><h2 className="title">Perguntas Frequentes</h2></div>
                {perguntasFrequentes.map((item, index) => (
                    <div className={styles.perguntas} key={index}>
                        <div className={styles.pergunta}>{item.pergunta}</div>
                        <div className={styles.resposta}>
                            {item.resposta} <a className={styles.link} href={item.linkUrl} target={"_blank"} rel={"noopener noreferrer"}>{item.linkTexto}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}