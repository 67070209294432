import { LinkRodapeInterface } from "../../interfaces/interface";

const constLinksRodape: Array<LinkRodapeInterface> = [
        {
            textUrl: 'Nossos Termos',
            url: '/politica-privacidade#termos-de-uso',
        },
        {
            textUrl: 'Política de Privacidade',
            url: '/politica-privacidade#politica-privacidade'
        },
        {
            textUrl: 'API',
            url: 'https://documenter.getpostman.com/view/1724124/TVRecVa8',
            target: '_blank'
        },
        {
            textUrl: 'ERP de Gestão',
            url: '/simob'
        },
        {
            textUrl: 'Site + CRM',
            url: '/moov'
        },
        {
            textUrl: 'Aplicativos',
            url: '/app'
        },
        {
            textUrl: 'Conteúdos para Imobiliárias',
            url: 'https://flexpro.com.br/blog/',
            target: '_blank'
        },
        {
            textUrl: 'Sou Cliente',
            url: 'https://enet.simob.com.br/login/93e0f5eabf8b4a349663e08bfa2f15b47',
            target: '_blank'
        },
        {
            textUrl: 'Fale Conosco',
            url: '/contato'
        },
        {
            textUrl: 'Sobre Nós',
            url: '/sobre-nos'
        }
    ]

export default constLinksRodape;