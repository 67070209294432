
import { TextoComponentInterface } from "../../../_config/interfaces/interface";
import TextoComponent from "../textoComponent/TextoComponent";
import styles from "./Caption.module.css";

export default function Caption(props: TextoComponentInterface) {
    return (
        <TextoComponent {...props} styleClass={styles.caption}/>
    )
}
